import { HtmlDiv, Heading, Link } from 'xuick'
import './DocHeadBlock.css'

export class DocHeadBlock extends HtmlDiv
{
  static class = 'DocHeadBlock'

  render() {
    const { section } = this.props
    if(!section) {
      return new Heading(
        section === null ?
          'Страница не найдена' :
          'Загрузка...',
      )
    }
    return [
      new Heading(section.title),
      new DocArchiveLink({
        children : 'Архив версий',
      }),
    ]
  }
}

class DocArchiveLink extends Link
{
  static class = 'DocArchiveLink'
}
