import { HtmlP } from 'htmlmodule'
import { Example } from './Example'
import { DialogButton } from './DialogButton'
import { Dialog } from './Dialog'
import { DialogContent } from './DialogContent'
import { DialogHead } from './DialogHead'
import { Heading } from './Heading'
import { DialogCancelButton } from './DialogCancelButton'
import { DialogBody } from './DialogBody'
import { CancelButton } from './CancelButton'

export default () => new Example([
  new DialogButton({
    label : 'DialogButton',
    dialog : props => new Dialog({
      ...props,
      modal : true,
      children : new DialogContent([
        new DialogHead([
          new Heading('Hello'),
          new DialogCancelButton,
        ]),
        new DialogBody([
          new HtmlP('This dialog demonstrates usage of the DialogButton component.'),
          new CancelButton({
            label : 'OK',
            classList : 'action',
          }),
        ]),
      ]),
    }),
  }),
])
