import { HtmlMain } from 'xuick'
import { DocHeadBlock } from './DocHeadBlock'
import { DocDocument } from './DocDocument'
import './DocMain.css'

export class DocMain extends HtmlMain
{
  static class = 'DocMain'

  render() {
    const section = this.props.section
    return [
      new DocHeadBlock({
        hidden : section && !section.parentId,
        section,
      }),
      new DocDocument({
        key : section?.key,
        section,
      }),
    ]
  }
}
