import { HtmlFooter, Inner } from 'xuick'
import { LandingSmallBlock } from './LandingSmallBlock.js'
import { LandingFooterContent } from './LandingFooterContent.js'
import './LandingFooter.css'

export class LandingFooter extends HtmlFooter
{
  static class = 'LandingFooter'

  render() {
    return new Inner([
      new LandingFooterContent,
      new LandingSmallBlock,
    ])
  }
}
