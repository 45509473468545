import { HtmlDiv, HtmlA } from 'xuick'
import params from './params.js'
import './LegalConfirmationBlock.css'

export class LegalConfirmationBlock extends HtmlDiv
{
  static class = 'LegalConfirmationBlock'

  static defaultProps = {
    termsText : 'пользовательское соглашение',
    privacyText : 'политику конфиденциальности',
    text : ({ terms, privacy }) => {
      return `Регистрируясь, вы принимаете ${ terms } и ${ privacy }`
    },
  }

  assign() {
    const { target, termsText, privacyText } = this.props
    const terms = new HtmlA({
      href : params.env.LANDING_ORIGIN + '/legal/terms',
      target,
      children : termsText,
    })
    const privacy = new HtmlA({
      href : params.env.LANDING_ORIGIN + '/legal/privacy',
      target,
      children : privacyText,
    })
    this.innerHTML = this.props.text({ terms, privacy })
  }
}
