import { DialogContent, DialogHead, Heading, DialogBody, MenuItem, Button } from 'xuick'
import { Icon } from './Icon.js'
import { ModalDialog } from './ModalDialog.js'
import { DialogMenu } from './DialogMenu.js'
import './DocNavDialog.css'

export class DocNavDialog extends ModalDialog
{
  static class = 'DocNavDialog'

  static defaultProps = {
    modal : true,
  }

  state = {
    section : null,
  }

  render() {
    const props = this.props
    const section = this.state.section
    const items = section?.items || props.tree
    return new DialogContent([
      new DialogHead([
        new Button({
          children : section ?
            new Icon('left_filled') :
            new Icon('close_filled'),
          onclick : () => {
            if(section) {
              this.setState({ section : null })
            }
            else this.close()
          },
        }),
        new Heading(section?.title || 'Меню'),
      ]),
      new DialogBody(
        new DialogMenu([
          !section && new MenuItem({
            key : '/',
            to : '/',
            onclick : () => this.close(),
            children : 'На главную',
          }),
          ...items.map(item => {
            let url = '/' + item.key
            if(item.items?.length) {
              return new MenuItem({
                key : url,
                children : item.title,
                onclick : () => {
                  this.setState({ section : item })
                },
              })
            }
            if(section) {
              url = '/' + section.key + url
            }
            return new MenuItem({
              key : url,
              to : url,
              onclick : () => this.close(),
              children : item.title,
            })
          }),
        ]),
      ),
    ])
  }
}
