import { HtmlDiv, App } from 'xuick'
import './Screen.css'

export class Screen extends HtmlDiv
{
  static class = 'Screen'

  get app() {
    return this.closest(App)
  }
}
