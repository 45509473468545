import { HtmlDiv } from 'xuick'
import { WelcomeBodyBlock } from './WelcomeBodyBlock.js'
import { LandingFooter } from './LandingFooter.js'
import params from './params.js'
import './WelcomeScreen.css'

export class WelcomeScreen extends HtmlDiv
{
  static class = 'WelcomeScreen'

  render() {
    document.title = params.env.SERVICE_NAME
    return [
      new WelcomeBodyBlock,
      new LandingFooter,
    ]
  }
}
