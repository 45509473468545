import { HtmlNav, Link } from 'xuick'
import './LandingNavBlock.css'

export class LandingNavBlock extends HtmlNav
{
  static class = 'LandingNavBlock'

  render() {
    return [
      new Link({
        to : '/about',
        children : 'О проекте',
      }),
      /*new Link({
        to : '/premium',
        children : 'Премиум',
      }),*/
      /*new Link({
        to : '/b2b',
        children : 'Для бизнеса',
      }),*/
      new Link({
        to : '/jobs',
        children : 'Вакансии',
      }),
      new Link({
        to : '/legal',
        children : 'Юридическая информация',
      }),
    ]
  }
}
