import lodash from 'lodash'
import { HtmlA, HtmlAddress, Group } from 'xuick'
import { Icon } from './Icon.js'
import params from './params.js'
import './LandingContactsBlock.css'

const {
  SERVICE_CONTACT_EMAIL,
  SERVICE_TELEGRAM_URL,
  SERVICE_VK_URL,
  SERVICE_YOUTUBE_URL,
} = params.env

const email = SERVICE_CONTACT_EMAIL && atob(SERVICE_CONTACT_EMAIL)

export class LandingContactsBlock extends HtmlAddress
{
  static class = 'LandingContactsBlock'

  render() {
    const links = lodash.compact([
      SERVICE_TELEGRAM_URL && new HtmlA({
        href : SERVICE_TELEGRAM_URL,
        target : '_blank',
        children : new Icon('telegram_custom'),
      }),
      SERVICE_VK_URL && new HtmlA({
        href : SERVICE_VK_URL,
        target : '_blank',
        children : new Icon('vk_filled'),
      }),
      SERVICE_YOUTUBE_URL && new HtmlA({
        href : SERVICE_YOUTUBE_URL,
        target : '_blank',
        children : new Icon('youtube_custom'),
      }),
    ])
    return [
      email && new HtmlA({
        href : `mailto:${ email }`,
        children : email,
      }),
      !!links.length && new Group(links),
    ]
  }
}
