import { HtmlDiv } from 'xuick'
import { StoreLink } from './StoreLink.js'
import './StoreLinkBlock.css'

export class StoreLinkBlock extends HtmlDiv
{
  static class = 'StoreLinkBlock'

  render() {
    return [
      new StoreLink({
        // href : 'https://www.rustore.ru/',
        href : '/android/planirus.apk',
        icon : 'logo-rustore',
        storeName : 'RuStore',
      }),
      /*new StoreLink({
        href : 'https://consumer.huawei.com/ru/mobileservices/appgallery/',
        icon : 'logo-huawei',
        storeName : 'AppGallery',
      }),*/
      /*new StoreLink({
        href : 'https://play.google.com',
        icon : 'logo-google-play-store',
        storeName : 'Google Play',
      }),*/
      /*new StoreLink({
        href : 'https://www.apple.com/ru/app-store/',
        icon : 'logo-apple',
        storeName : 'App Store',
      }),*/
    ]
  }
}
