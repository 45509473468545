import { RoleDocument } from 'xuick'
import { DocContent } from './adaptive/DocContent'
import './DocDocument.css'

export class DocDocument extends RoleDocument
{
  static class = 'DocDocument'

  render() {
    const { section } = this.props
    if(!section) {
      return null
    }
    return new DocContent({
      innerHTML : section.content,
    })
  }
}
