import { HtmlDiv } from 'xuick'
import { LandingHeader } from './LandingHeader.js'
import { WelcomeMain } from './WelcomeMain.js'
import { LegalConfirmationBlock } from './LegalConfirmationBlock.js'
import './WelcomeBodyBlock.css'

export class WelcomeBodyBlock extends HtmlDiv
{
  static class = 'WelcomeBodyBlock'

  render() {
    return [
      new LandingHeader,
      new WelcomeMain,
      new LegalConfirmationBlock,
    ]
  }
}
