// todo ApiError
export class AppError extends Error
{
  /**
   * @param {{}|string} props
   * @param {string} props.name
   * @param {string} props.message
   */
  constructor(props) {
    if(typeof props === 'string') {
      props = {
        message : props,
      }
    }
    super(props.message)
    Object.assign(this, props)
  }
}
