import { Link, Label, Div } from 'xuick'
import { Icon } from './Icon.js'
import './StoreLink.css'

export class StoreLink extends Link
{
  static class = 'StoreLink'

  static defaultProps = {
    target : '_blank',
  }

  render() {
    return [
      new Icon(this.props.icon),
      new Label([
        'Скачать в',
        new Div(this.props.storeName),
      ]),
    ]
  }
}
