import { Menu, MenuItem } from 'xuick'
import './DocNavMenu.css'

export class DocNavMenu extends Menu
{
  static class = 'DocNavMenu'

  render() {
    return this.props.tree.map(
      item => new MenuItem({
        to : '/' + item.key,
        children : item.title,
        nav : true,
      }),
    )
  }
}
