import { HtmlDiv, HtmlSmall } from 'xuick'
import { LegalConfirmationBlock } from './LegalConfirmationBlock.js'
import params from './params.js'
import './LandingSmallBlock.css'

export class LandingSmallBlock extends HtmlDiv
{
  static class = 'LandingSmallBlock'

  render() {
    const year = (new Date).getFullYear()
    return [
      new LegalConfirmationBlock({
        tagName : 'small',
        text : ({ terms, privacy }) => {
          return `Используя сервис, вы принимаете ${ terms } и ${ privacy }`
        },
      }),
      new HtmlSmall(params.env.SERVICE_REQUISITES),
      new HtmlSmall(`© ${ params.env.SERVICE_NAME } ${ year } Все права защищены`),
    ]
  }
}
