import { RoleTree, Link, Group } from 'xuick'
import './DocNavTree.css'

const H2_RE = /<h2 id="([^"]+)">([^<]+)<\/h2>/g
const OL_RE = /^\s*<ol>/

export class DocNavTree extends RoleTree
{
  static class = 'DocNavTree'

  render() {
    const { index, section } = this.props
    if(!section) {
      return null
    }
    const items = section.parentId ?
      index[section.parentId].items :
      section.items
    const matches = section.content ?
      Array.from(section.content.matchAll(H2_RE)) :
      []
    const classList = OL_RE.test(section.content) && 'list'
    const hash = decodeURIComponent(location.hash)
    const children = matches.map(([, id]) => {
      const href = '#' + id
      return new Link({
        key : id,
        href : href === hash ? null : href,
        role : 'TreeItem',
        children : id.replaceAll('_', ' '),
      })
    })
    if(matches.length && !items.length) {
      return children
    }
    return items.map(item => {
      item = index[item.id]
      return [
        new Link({
          key : item.key,
          to : item.path,
          role : 'TreeItem',
          children : item.title,
        }),
        item.key === section.key && !!matches.length && new Group({
          key : item.key + '-group',
          classList,
          children,
        }),
      ]
    })
  }
}
