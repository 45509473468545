import { RoleDialog, Heading, Button, Link, Content } from 'xuick'
import './CookieDialog.css'

export class CookieDialog extends RoleDialog
{
  static class = 'CookieDialog'

  constructor(props) {
    if(localStorage.getItem('cookie')) {
      return []
    }
    super(props)
  }

  state = {
    hidden : false,
  }

  render() {
    this.hidden = this.state.hidden
    return new Content([
      new Heading('Мы используем файлы cookie'),
      new Link({
        to : '/legal/cookie',
        children : 'Соглашение об использовании',
      }),
      new Button({
        label : 'Понятно',
        onclick : this.#onButtonClick,
      }),
    ])
  }

  #onButtonClick = () => {
    localStorage.setItem('cookie', 'true')
    this.setState({
      hidden : true,
    })
  }
}
