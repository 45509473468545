import { App, AlertDialog } from 'xuick'
import { AppError } from './adaptive/AppError.js'
import { CookieDialog } from './CookieDialog.js'
import { WelcomeScreen } from './WelcomeScreen.js'
import { DocScreen } from './DocScreen.js'
import './LandingApp.css'

export class LandingApp extends App
{
  static class = 'LandingApp'

  state = {
    ...this.state,
    error : null,
  }

  init() {
    super.init()
    window.addEventListener('unhandledrejection', this.onUnhandledRejection)
  }

  destroy() {
    super.destroy()
    window.removeEventListener('unhandledrejection', this.onUnhandledRejection)
  }

  render() {
    const state = this.state
    return [
      new AlertDialog({
        error : state.error,
        open : !!state.error,
        oncancel : () => {
          this.setState({ error : null })
        },
      }),
      new CookieDialog,
      App.router([
        {
          path : '/',
          render : WelcomeScreen,
        },
        {
          path : /^(.*)$/,
          render : pathname => new DocScreen({ pathname }),
        },
      ]),
    ]
  }

  onUnhandledRejection = e => {
    if(e.reason instanceof AppError) {
      e.preventDefault()
      this.setState({ error : e.reason })
    }
    else throw e.reason
  }
}
