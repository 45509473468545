import { Main, ContentBlock } from 'xuick'
import { LoadingIndicator } from './LoadingIndicator.js'
import { DocNavTree } from './DocNavTree.js'
import { DocNavMenu } from './DocNavMenu.js'
import { DocMain } from './DocMain.js'
import './DocMainBlock.css'

export class DocMainBlock extends Main
{
  static class = 'DocMainBlock'

  render() {
    const { tree, index, section } = this.props
    if(!tree || section === undefined) {
      return new LoadingIndicator
    }
    return [
      new DocNavTree({ tree, index, section }),
      new ContentBlock([
        new DocNavMenu({ tree }),
        new DocMain({ section }),
      ]),
    ]
  }
}
