import { HtmlDiv } from 'xuick'
import { LandingNavBlock } from './LandingNavBlock.js'
import { LandingContactsBlock } from './LandingContactsBlock.js'
import { StoreLinkBlock } from './StoreLinkBlock.js'
import './LandingFooterContent.css'

export class LandingFooterContent extends HtmlDiv
{
  static class = 'LandingFooterContent'

  render() {
    return [
      new LandingNavBlock,
      new LandingContactsBlock,
      new StoreLinkBlock,
    ]
  }
}
