import { HtmlDiv } from 'htmlmodule'
import { Example } from 'xuick'
import { Command } from './Command'
import { Control } from './Control'

class CommandTest extends HtmlDiv
{
  render() {
    return new Command(new Control('Command'))
  }
}

export default () => new Example([
  new CommandTest,
])
