import { Dialog } from 'xuick'
import './ModalDialog.css'

export class ModalDialog extends Dialog
{
  static class = 'ModalDialog'

  static defaultProps = {
    modal : true,
  }
}
