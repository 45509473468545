import { HtmlHeader, Button } from 'xuick'
import { Icon } from './Icon.js'
import { DocNavDialog } from './DocNavDialog.js'
import { NomadLogo } from './NomadLogo.js'
// import { LandingAuthDialog } from './LandingAuthDialog.js'
import params from './params.js'
import './LandingHeader.css'

export class LandingHeader extends HtmlHeader
{
  static class = 'LandingHeader'

  render() {
    const tree = this.props.tree
    return [
      tree && new Button({
        key : 'menu',
        type : 'menu',
        children : new Icon('menu_filled'),
        dialog : props => new DocNavDialog({ ...props, tree }),
      }),
      new NomadLogo({
        key : 'logo',
        to : '/',
      }),
      new Button({
        key : 'auth',
        label : 'Вход в приложение',
        classList : 'accent',
        // dialog : LandingAuthDialog,
        to : params.env.ORIGIN,
      }),
    ]
  }
}
