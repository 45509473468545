import { HtmlMain, Heading, Paragraph, Content, Button } from 'xuick'
import { WelcomeScreenshot } from './WelcomeScreenshot.js'
import { StoreLinkBlock } from './StoreLinkBlock.js'
import params from './params.js'
import './WelcomeMain.css'

export class WelcomeMain extends HtmlMain
{
  static class = 'WelcomeMain'

  render() {
    return [
      new WelcomeScreenshot,
      new Content([
        new Heading({
          innerHTML : 'Планировать проще, чем когда-либо',
        }),
        new Paragraph('Создайте поездку всего в три клика'),
        new Paragraph('Откройте новые, интересные вам места'),
        new Paragraph({
          innerHTML : 'Добавляйте друзей и редактируйте план вместе с ними',
        }),
        new StoreLinkBlock,
        new Button({
          label : 'Вход в приложение',
          classList : 'accent',
          to : params.env.ORIGIN,
        }),
      ]),
    ]
  }
}
