import './landing.css'
import { ElemType } from 'xuick'
import { LandingApp } from './LandingApp.js'
import params from './params.js'

ElemType.setDebugMode(params.env.NODE_ENV === 'development')

const render = app => {
  LandingApp.destroy(app)
  return LandingApp.render({}, document.body)
}

let app = render()

if(module.hot) {
  module.hot.accept(['./LandingApp'], () => app = render(app))
}
