import { App, BodyBlock } from 'xuick'
import { Screen } from './Screen'
import { LandingHeader } from './LandingHeader.js'
import { DocMainBlock } from './DocMainBlock.js'
import { LandingFooter } from './LandingFooter.js'
import './DocScreen.css'

export class DocScreen extends Screen
{
  static class = 'DocScreen'

  static async callMethod(methodName, ...args) {
    const input = `api/${ methodName }`
    const url = new URL(input, location.origin)
    const res = await fetch(url, {
      method : 'POST',
      headers : {
        'Content-Type' : 'application/json',
        'Accept' : 'application/json',
      },
      body : JSON.stringify(args),
    })
    if(!res.ok) {
      throw new Error(decodeURIComponent(res.statusText))
    }
    return res.json()
  }

  state = {
    ...this.state,
    tree : undefined,
    index : undefined,
    section : undefined,
  }

  async init() {
    super.init()
    if(!this.state.tree) {
      await this.#loadTree()
    }
    await this.#loadSection()
  }

  render() {
    const { tree, index, section } = this.state
    if(section === undefined) {
      document.title = 'Загрузка...'
    }
    else if(section === null) {
      document.title = 'Страница не найдена'
    }
    else if(section) {
      document.title = section.title
    }
    return [
      new BodyBlock([
        new LandingHeader({ tree }),
        new DocMainBlock({ tree, index, section }),
      ]),
      new LandingFooter,
    ]
  }

  async update(prevProps, prevState) {
    if(prevProps.pathname !== this.props.pathname) {
      await this.#loadSection()
      document.documentElement.scrollTop = 0
    }
  }

  async #loadTree() {
    const tree = await DocScreen.callMethod('getSectionsTree')
    const index = {}
    tree.forEach(section => {
      section.path = '/' + section.key
      index[section.path] = index[section.id] = section
      section.items.forEach(item => {
        item.path = `/${ section.key }/${ item.key }`
        index[item.path] = index[item.id] = item
      })
    })
    this.setState({ tree, index })
  }

  async #loadSection() {
    const index = this.state.index
    let section = index[this.props.pathname] || null
    if(section) {
      section = await DocScreen.callMethod('getSection', section.id)
    }
    if(section?.items.length && !section.content) {
      section = section.items[0]
      section = index[section.id]
      await this.app.navigate(section.path, {
        replace : true,
      })
      return
    }
    this.setState({ section })
  }

  get app() {
    return this.closest(App)
  }
}
